import { AUTH_USER_UPDATE, LOGIN, LOGOUT } from "../constants/Auth.constants";
import { AuthUserType, InitialUserStateType } from "../types/context.type";

export type AuthActionType = {
  type: "LOGIN" | "LOGOUT" | "AUTH_USER_UPDATE";
  payload: AuthUserType | null;
};

const AuthReducer = (state: InitialUserStateType, action: AuthActionType) => {
  switch (action.type) {
    case LOGIN:
      //delete action.payload?.token;
      //delete action.payload?.password;
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    case LOGOUT:
      localStorage.removeItem("user");
      localStorage.removeItem("identifier");
      localStorage.removeItem("password");
      return { ...state, user: action.payload };
    case AUTH_USER_UPDATE:
      // delete action.payload?.token;
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
