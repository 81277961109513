import React, {lazy} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./routes/Routes"
import "./assets/tailwind.css";
import "./assets/theme.light.css";
import "./assets/main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import GlobalContextProvider from "./context/GlobalContextProvider";
import { SWRConfig } from "swr";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <GlobalContextProvider>
    <BrowserRouter>
      <SWRConfig value={{ revalidateOnFocus: false }}>
        <App />
      </SWRConfig>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme={"light"}
        pauseOnHover
      />
    </BrowserRouter>
  </GlobalContextProvider>
);
