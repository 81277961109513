import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import AuthReducer from "./reducers/Auth.reducer";
import {
  InitialSideBarStateType,
  InitialUserStateType,
  VariantFileActionType,
  VariantFileType,
} from "./types/context.type";
import { AuthActionType } from "./reducers/Auth.reducer";
import SideBarToggleReducer, {
  SideBarActionType,
} from "./reducers/SideBare.reducer";
import Fetcher from "../utils/Fetcher";
import useSWR from "swr";
import VariantFileReducer from "./reducers/VariantFile.reducer";

type GlobalContextProviderChildrenType = {
  children: ReactNode;
};

let sideBarInitialState = {
  isOpen: localStorage.getItem("isSideBarOpen")
    ? JSON.parse(localStorage.getItem("isSideBarOpen") || "{}")
    : true,
};

type AuthContextPropsTypes = {
  auth: InitialUserStateType;
  authDispatch: Dispatch<AuthActionType>;
};
type VariantFileContextProps = {
  variantFilestore: VariantFileType[];
  variantFilesDispatch: Dispatch<VariantFileActionType>;
};

type SideBarContextPropsTypes = {
  sideBar: InitialSideBarStateType;
  sideBarDispatch: Dispatch<SideBarActionType>;
};

type ContextPropsTypes = AuthContextPropsTypes & SideBarContextPropsTypes & VariantFileContextProps

const GlobalContext = createContext<ContextPropsTypes>({} as ContextPropsTypes);

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalContextProvider = ({
  children,
}: GlobalContextProviderChildrenType) => {
  let { data, isValidating } = useSWR(
    localStorage.getItem("user") ? `/auth/user` : null,
    Fetcher
  );

  let initialUserState = {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") || "{}")
      : null,
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (data && !isValidating) {
        //delete data.response.token;
        //delete data.response.password;
        localStorage.setItem("user", JSON.stringify(data.response));
      }
    }
  }, [data, isValidating]);

  const [auth, authDispatch] = useReducer(AuthReducer, initialUserState);
  const [sideBar, sideBarDispatch] = useReducer(
    SideBarToggleReducer,
    sideBarInitialState
  );
  const [variantFilestore, variantFilesDispatch] = useReducer(
    VariantFileReducer,
    []
  );
  return (
    <GlobalContext.Provider
      value={{ auth, authDispatch, sideBar, sideBarDispatch,  variantFilestore,
        variantFilesDispatch }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
