import { SAVE_VARIANT_FILE } from '../constants/ProductVariant.constants';
import { InitialVariantFileStateType, VariantFileActionType } from '../types/context.type';

const initialState: InitialVariantFileStateType = [];

const VariantFileReducer = (state: InitialVariantFileStateType = initialState, action: VariantFileActionType) => {
    
    switch (action.type) {
        case SAVE_VARIANT_FILE:
            return [...state, action.payload];
        default:
            return state;
    }
};

export default VariantFileReducer;
