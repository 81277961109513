import axios from 'axios';
import { Config } from '../shared/Config';
const axiosInstance = axios.create({
    baseURL: Config.BaseUrl,
    withCredentials: true,
    headers: {
        authorization: 'Bearer ' + (JSON.parse(localStorage.getItem('user') || 'null') || {})?.token,
    },
});
export default axiosInstance;
